:root {
    --primary: #06A3DA;
    --secondary: #34AD54;
    --light: #EEF9FF;
    --dark: #091E3E;
}

/*** Spinner ***/

.spinner {
    width: 40px;
    height: 40px;
    background: var(--primary);
    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px)
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}

/*** Heading ***/

h1,
h2,
.fw-bold {
    font-weight: 800 !important;
    /* font-family: sans-serif !important; */
}

h3,
h4,
.fw-semi-bold {
    font-weight: 700 !important;
    /* font-family: sans-serif !important; */

}

h5,
h6,
.fw-medium {
    font-weight: 600 !important;
}

/*** Button ***/

.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: .5s;
}

.btn-primary,
.btn-secondary {
    color: #FFFFFF;
    box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
    box-shadow: inset 0 0 0 0 var(--primary);
}

.btn-secondary:hover {
    box-shadow: inset 0 0 0 0 var(--secondary);
}

.btn-square {
    width: 36px;
    height: 36px;
}

.btn-sm-square {
    width: 30px;
    height: 30px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}


/* navbar  */

.navbar-dark .navbar-nav .nav-link {
    font-family: 'Nunito', sans-serif;
    position: relative;
    margin-left: 25px;
    padding: 35px 0;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: rgba(0, 123, 255, 1);
}

.navbar-dark .navbar-brand h1 {
    color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
    color: rgba(0, 123, 255, 1) !important;
    border-color: rgba(0, 123, 255, 1) !important;
}

@media (max-width: 991.98px) {
    .sticky-top.navbar-dark {
        position: relative;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .sticky-top.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        color: rgba(0, 123, 255, 1);
    }

    .navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}

@media (min-width: 992px) {
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(256, 256, 256, .1);
        z-index: 999;
    }

    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 50%;
        background: var(--primary);
        transition: .5s;
    }

    .navbar-dark .navbar-nav .nav-link:hover::before,
    .navbar-dark .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }

    .navbar-dark .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }

    .sticky-top.navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}

/*** Carousel ***/

.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(9, 30, 62, .7);
    z-index: 1;
}

@media (max-width: 576px) {
    .carousel-caption h5 {
        font-size: 14px;
        font-weight: 500 !important;
    }

    .carousel-caption h1 {
        font-size: 30px;
        font-weight: 600 !important;
    }
}

.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}



.hidden {
    display: none;
}



.text-white {
    color: #fff;
}

.mr-4 {
    margin-right: 1rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.rounded {
    border-radius: 0.25rem;
}

.hamburger-btn {
    cursor: pointer;
}



.hamburger-btn {
    background-color: black !important;
}



@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.typing-animation {
    overflow: hidden;
    white-space: nowrap;
    animation: typing 2s steps(20) forwards;
}




/*** Section Title ***/

.section-title::before {
    position: absolute;
    content: "";
    width: 150px;
    height: 5px;
    left: 0;
    bottom: 0;
    background: var(--primary);
    border-radius: 2px;
}

.section-title.text-center::before {
    left: 50%;
    margin-left: -75px;
}

.section-title.section-title-sm::before {
    width: 90px;
    height: 3px;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 5px;
    bottom: 0px;
    background: #FFFFFF;
    -webkit-animation: section-title-run 5s infinite linear;
    animation: section-title-run 5s infinite linear;
}

.section-title.section-title-sm::after {
    width: 4px;
    height: 3px;
}

.section-title.text-center::after {
    -webkit-animation: section-title-run-center 5s infinite linear;
    animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
    -webkit-animation: section-title-run-sm 5s infinite linear;
    animation: section-title-run-sm 5s infinite linear;
}

@keyframes section-title-run {
    0% {
        left: 0;
    }

    50% {
        left: 145px;
    }

    100% {
        left: 0;
    }
}

@keyframes section-title-run-center {
    0% {
        left: 50%;
        margin-left: -75px;
    }

    50% {
        left: 50%;
        margin-left: 45px;
    }

    100% {
        left: 50%;
        margin-left: -75px;
    }
}

@keyframes section-title-run-sm {
    0% {
        left: 0;
    }

    50% {
        left: 85px;
    }

    100% {
        left: 0;
    }
}



/* my style prev */
.icons>div i {
    font-size: 2rem;
}

.footer-top {
    padding: 11rem 0;
}


.services-card img {
    width: 40px;
}

.pricing-card {
    background-color: rgba(255, 255, 255, 0.1);
    /* height: 25rem; */
    border: 1px solid #06A3DA;
    
}



.pricing-card h2 {
    color: #06A3DA !important;
    font-size: 1.12rem;
}

.price-text {
    margin-top: 5rem;
    /* color: #99999999; */
}



.project-card {
    background-color: #FF6347;
}

.demo-card {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.demo-card:hover {
    transform: translateY(-5px);
}



.most-populor {
    background-color: rgb(254 240 138) !important;
}

.most-populor span {
    font-size: 1.12rem;
    font-weight: 600;
}

.row .pricing-card:nth-of-type(4) .price-text {
    color: #99999999 !important;
}

.pricing-btn {
    background-color: #06A3DA;
    /* width: 50% !important; */
    color: white;
    border: 1px solid #ccc;
    padding: 5px 3px !important;
    border-radius: 8px !important;
}

.pricing-btn:hover {
    -webkit-box-shadow: 0px 0px 22px 7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 22px 7px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 22px 7px rgba(0, 0, 0, 0.75);
}

.amount {
    font-size: 1.25rem;
}

.amount span {
    color: rgb(107 114 128);
    font-size: 0.87rem;
}

.projects .card-body, .demo .card-body {
    background-color: #091E3E;
}

.contact-us-btn a {
    text-decoration: none;
    color: white;
}



.footer-links i {
    font-size: 2rem;
    margin-left: 1rem;
}


.tech-card {
    width: 150px;
    background-color: rgb(169, 204, 242);
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}



.tech-card img {
    aspect-ratio: 3/3;
    border-radius: 50%;
}



.tech-card:hover {
    transform: translateY(-5px);
}

.techs-container {
    margin-top: 4rem;
}


.footer-top button {
    padding: 10px 0 !important;
    background-color: black !important;
    border: 2px solid white;
}



.custom-card {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    height: 25rem;
    border: 1px solid rgb(169, 166, 218);
}

/* .custom-card:hover {
    border:2px solid linear-gradient(90deg, rgba(169, 166, 218, 1) 4%, rgba(111, 223, 114, 1) 45%);
} */



.custom-card p {
    color: #99999999;
    margin-top: 5rem;

}

.custom-icon {
    width: 13%;
    margin-bottom: 3rem;
}

.custom-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: #ffc107;
}

.custom-text {
    font-size: 0.875rem;
    color: #ccc;
}


.Card p {
    color: #99999999;
}


.social-media-icons a i {
    font-size: 30px;
    margin-left: 1rem;
}


/* .card-body{
    background-color: rgba(255, 255, 255, 0.1) !important;

} */

.card-title {
    color: rgb(107 114 128);
}


.service-item {
    position: relative;
    height: 300px;
    padding: 0 30px;
    transition: .5s;
    background-color: #EEF9FF !important;
}

.service-item .service-icon {
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 2px;
    transform: rotate(-45deg);
}

.service-item .service-icon i {
    transform: rotate(45deg);
}

.service-item a.btn {
    position: absolute;
    width: 60px;
    bottom: -48px;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
}

.service-item:hover a.btn {
    bottom: -24px;
    opacity: 1;
}

.last-item {
    background-color: #06A3DA;
}


/*** Testimonial ***/

.testimonial-carousel .owl-dots {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: #DDDDDD;
    border-radius: 2px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 30px;
    background: var(--primary);
}

.testimonial-carousel .owl-item.center {
    position: relative;
    z-index: 1;
}

.testimonial-carousel .owl-item .testimonial-item {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: #FFFFFF !important;
    box-shadow: 0 0 30px #DDDDDD;
}


.contact-form {
    background-color: #06A3DA;
}


/*** Team ***/

.team-item {
    transition: .5s;
}

.team-social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.team-social a.btn {
    position: relative;
    margin: 0 3px;
    margin-top: 100px;
    opacity: 0;
}

.team-item:hover {
    box-shadow: 0 0 30px #DDDDDD;
}

.team-item:hover .team-social {
    background: rgba(9, 30, 62, .7);
}

.team-item:hover .team-social a.btn:first-child {
    opacity: 1;
    margin-top: 0;
    transition: .3s 0s;
}

.team-item:hover .team-social a.btn:nth-child(2) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .05s;
}

.team-item:hover .team-social a.btn:nth-child(3) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .1s;
}

.team-item:hover .team-social a.btn:nth-child(4) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .15s;
}

.team-item .team-img img,
.blog-item .blog-img img {
    transition: .5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
    transform: scale(1.15);
}

/*** Miscellaneous ***/

@media (min-width: 991.98px) {
    .facts {
        position: relative;
        margin-top: -75px;
        z-index: 1;
    }
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

/* .bg-header {
    background: linear-gradient(rgba(9, 30, 62, .7), rgba(9, 30, 62, .7)), url(../img/carousel-1.jpg) center center no-repeat;
    background-size: cover;
} */

.link-animated a {
    transition: .5s;
}

.link-animated a:hover {
    padding-left: 10px;
}

@media (min-width: 767.98px) {
    .footer-about {
        margin-bottom: -75px;
    }
}